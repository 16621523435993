* {
    /* font-family: 'Indie Flower', cursive; */
    font-family: 'Short Stack', cursive;
    /* background-color: rgb(143, 168, 220); */
}

.outerContainer {
    position: relative;
    padding-bottom: 80px;
    /* font-size: 25px; */
}

/* Layout: Navigation and Footer */
/* .nav {
    background-color:  rgb(157, 157, 157);
} */

.navLink {
    color:black;
    font-size: 25px;
}

.footer {
    position: fixed;
    bottom: 0px;
    width: 100vw;
    font-size: 15px;
    /* background-color: rgb(157, 157, 157); */
} 
/* End layout */

/* Home View */
.typingText {
    font-size: 20px;
}

.animate__animated.animate__bounce {
    --animate-duration: 2s;
  }

.profilePhoto {
    align-self: center;
}


/* End Home View */

/* About Me */
.aboutText {
    padding-top: 30px;
}

.aboutPhoto {
    width: 80%;
    border-radius: 2%;
}

.aboutPhotoContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.aboutMe {
    font-size: 18px;
} 

.skills {
    background-color: rgb(17, 20, 89);
    color: white;
}

/* End About Me */

/* Project Carousel */

.cardButton {
    background-color: rgb(17, 20, 89);
}

/* .projectImage {
    width: 90%;
    align-self: center;
} */

.carousel {
    margin: 20px;
    padding: 50px;
    background-color: rgb(17, 20, 89);
    width: 28rem;
}
/* End Carousel */

/* List of projects */
.projImage {
    width: 70%;
}

.projectHeader {
    font-weight: 800;
    color: rgb(17, 20, 89);
}
/* End list of projects */